import React, { useState } from "react";
import Navbar from "./Navbar";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import "./index.css";
import DragAndDrop from "./DragAndDrop";
import { uploadFileToAzure } from "./drag";
import SignInPage from "./SignInPage";

function App() {
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  const handleFileUpload = async (file) => {
    const fileSizeInMB = file.size / (1024 * 1024);
    if (fileSizeInMB > 100) {
      alert("File size exceeds 100MB. Please upload a smaller file.");
      return;
    }
    const fileName = file.name;

    try {
      setIsUploading(true);
      setUploadProgress(0);

      const result = await uploadFileToAzure(
        fileName,
        file,
        (percentCompleted) => {
          setUploadProgress(percentCompleted);
        }
      );

      if (result.success) {
        setUploadedFile({ fileName: result.name, fileLink: result.url });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsUploading(false);
    }
  };

  const handleCopyLink = () => {
    if (uploadedFile) {
      navigator.clipboard
        .writeText(uploadedFile.fileLink)
        .then(() => alert("Link copied."))
        .catch((error) => alert("Error copying link to clipboard"));
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("googleSignInInfo");
    window.location.href = "/signin";
  };

  return (
    <Router>
      <div className="App">
        <Navbar />
        <div className="content">
          <Switch>
            <Route path="/create">
              <DragAndDrop onFileDrop={handleFileUpload} />
              {isUploading && (
                <div className="progress">

                  <div className="progress-container">
                  <div
                    className="progress-bar"
                    style={{ width: `${uploadProgress}%` }}
                  ></div>
                  <span>{uploadProgress}%</span>
                </div>
                </div>
                
              )}
              {uploadedFile && (
                <div>
                  <h2>Uploaded File Details</h2>
                  <p>File Link: {uploadedFile.fileLink}</p>
                  <button onClick={handleCopyLink} className="copy">
                    Copy Link
                  </button>
                </div>
              )}
              <button onClick={handleLogout} className="logout-button">
                Logout
              </button>
            </Route>
            <Route path="/signin">
              <SignInPage />
            </Route>
            <Redirect from="/" to="/signin" />
          </Switch>
        </div>
      </div>
    </Router>
  );
}

export default App;
