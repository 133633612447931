import { BlobServiceClient } from "@azure/storage-blob";
window.Buffer = window.Buffer || require("buffer").Buffer;

export async function uploadFileToAzure(fileName, file, onProgress) {
  if (!file) {
    console.error("No file selected");
    return { error: "No file selected", success: false };
  }

  const accountName = "spotmiesstorage";
  const containerName = "storage";
  const sasToken =
    "sp=racwdli&st=2024-08-20T05:21:08Z&se=2025-08-20T13:21:08Z&sv=2022-11-02&sr=c&sig=tcMbMw0NzS%2BGanI2BWWqxIf6nJIuB5WNVEeLp3%2FTXXM%3D";

  const blobServiceClient = new BlobServiceClient(
    `https://${accountName}.blob.core.windows.net?${sasToken}`
  );

  const containerClient = blobServiceClient.getContainerClient(containerName);
  const blockBlobClient = containerClient.getBlockBlobClient(fileName);

  try {
    const uploadResponse = await blockBlobClient.uploadBrowserData(file, {
      blockSize: 4 * 1024 * 1024, // 4MB chunk size
      concurrency: 3, // Allows concurrent uploads
      onProgress: (progress) => {
        if (onProgress) {
          const percentCompleted = Math.round(
            (progress.loadedBytes / file.size) * 100
          );
          onProgress(percentCompleted);
        }
      },
    });

    console.log("File uploaded successfully!", uploadResponse);

    const fileUrl = `https://${accountName}.blob.core.windows.net/${containerName}/${fileName}`;
    console.log(fileUrl);

    return { url: fileUrl, data: uploadResponse, success: true };
  } catch (error) {
    console.error("Error uploading file:", error.message);
    return { error: "Error uploading file", data: error, success: false };
  }
}
